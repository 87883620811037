<template>
  <DefaultModal
    :id="id"
    :width="width"
    :title="title"
    content-class="nested"
    persistent
    :close-callback="closeCallback"
    @created="onCreated"
    @on-enter="handleConfirm"
    @hide="onModalHide"
  >
    <div class="confirm">
      <div class="confirm__text">
        {{ text }}
      </div>
    </div>

    <template #footer>
      <v-btn
        class="sl-button"
        text
        @click="handleConfirm"
      >
        {{ $t('Common.Yes') }}
      </v-btn>
      <v-btn
        class="sl-button"
        text
        @click="handleReject"
      >
        {{ $t('Common.No') }}
      </v-btn>
      <v-btn
        v-if="!withoutCancel"
        class="sl-button"
        text
        @click="handleCancel"
      >
        {{ $t('Common.Cancel') }}
      </v-btn>
    </template>
  </DefaultModal>
</template>

<script>
import { modal } from '@/mixins/modal';
import DefaultModal from '@/components/Modals/DefaultModal.vue';

export default {
  name: 'ConfirmModal',
  components: {
    DefaultModal
  },
  mixins: [modal],
  props: {
    id: {
      type: String,
      required: true
    },
    width: {
      type: String,
      required: false,
      default: '400'
    },
    withoutCancel: Boolean
  },
  data() {
    return {
      title: null,
      text: null,
      noHandlerOnClose: false,
      yesHandler: null,
      noHandler: null
    };
  },
  computed: {
    closeCallback() {
      if (this.noHandlerOnClose) {
        return this.noHandler;
      }

      return null;
    }
  },
  methods: {
    onModalHide() {
      this.title = null;
      this.text = null;
      this.noHandlerOnClose = false;
      this.yesHandler = null;
      this.noHandler = null;
    },
    onCreated(props) {
      Object.keys(props).forEach(key => {
        if (props[key]) {
          this[key] = props[key];
        }
      });
    },
    handleConfirm() {
      if (this.yesHandler) {
        this.yesHandler();
      } else {
        this.$emit('yes');
      }
      this.hideModal(this.id);
    },
    handleReject() {
      if (this.noHandler) {
        this.noHandler();
      } else {
        this.$emit('no');
      }
      this.hideModal(this.id);
    },
    handleCancel() {
      this.hideModal(this.id);
    }
  }
};
</script>
<style lang="scss" scoped>
.confirm {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  font-size: 13px;
  line-height: 150%;
}
</style>
